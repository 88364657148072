(function (global, factory) {
  typeof exports === 'object' && typeof module !== 'undefined'
    ? (module.exports = factory())
    : typeof define === 'function' && define.amd
    ? define(factory)
    : ((global =
        typeof globalThis !== 'undefined' ? globalThis : global || self),
      (global.Accordion = factory()));
})(this, function () {
  'use strict';

  function _extends() {
    _extends =
      Object.assign ||
      function (target) {
        for (var i = 1; i < arguments.length; i++) {
          var source = arguments[i];

          for (var key in source) {
            if (Object.prototype.hasOwnProperty.call(source, key)) {
              target[key] = source[key];
            }
          }
        }

        return target;
      };

    return _extends.apply(this, arguments);
  }

  var defaults = {
      elmClass: 'ac-item',
      triggerClass: 'ac-trigger',
      bodyClass: 'ac-body',
      multipleShow: true,
      // initialShow: [ 1, 2, 3, 4 ],
      initialShow: 0,
      duration: 600,
    },
    classes = {
      visible: 'is-visible',
      active: 'ac-enable',
    };

  var Accordion = function Accordion(target, options) {
    if (target === void 0) {
      target = '.accordion-container';
    }

    this.accordion = target;
    this.options = _extends(_extends({}, defaults), options);
    this.elms = '';
    this.listener = [];
    this.init();
  };

  Accordion.prototype = {
    init: function init() {
      if (Array.isArray(this.accordion)) {
        var elms = [];

        for (var i = 0; i < this.accordion.length; i++) {
          var hoge = document
            .querySelector(this.accordion[i])
            .querySelectorAll('.' + this.options.elmClass);

          for (var j = 0; j < hoge.length; j++) {
            elms.push(hoge[j]);
          }
        }

        this.elms = elms;
      } else {
        this.elms = document
          .querySelector(this.accordion)
          .querySelectorAll('.' + this.options.elmClass);
      }

      for (var _i = 0; _i < this.elms.length; _i++) {
        var elm = this.elms[_i],
          trigger = elm.querySelector('.' + this.options.triggerClass),
          body = elm.querySelector('.' + this.options.bodyClass);
        // if (defaults.elmClass != this.options.elmClass) elm.classList.add(defaults.elmClass);
        //if (defaults.triggerClass != this.options.triggerClass) trigger.classList.add(defaults.triggerClass);
        if (defaults.bodyClass != this.options.bodyClass)
          body.classList.add(defaults.bodyClass);

        this._hide(elm);

        elm.classList.add(classes.active);
        body.style.transitionDuration = this.options.duration + 'ms';
        this.listener[_i] = this._toggle.bind(this, elm, body, trigger);
        trigger.addEventListener('click', this.listener[_i], {
          passive: true,
        });
      }

      if (this.options.initialShow) {
        if (Array.isArray(this.options.initialShow)) {
          for (var _i2 = 0; _i2 < this.options.initialShow.length; _i2++) {
            var _elm = this.elms[this.options.initialShow[_i2] - 1],
              _body = _elm.querySelector('.' + this.options.bodyClass);

            this._toggle(_elm, _body);
          }
        } else if (typeof this.options.initialShow === 'number') {
          var _elm2 = this.elms[this.options.initialShow - 1],
            _body2 = _elm2.querySelector('.' + this.options.bodyClass);

          this._toggle(_elm2, _body2);
        }
      }
    },
    _toggle: function _toggle(elm, body) {
      if (elm.classList.contains(classes.visible)) {
        this._hide(elm);
      } else {
        if (!this.options.multipleShow) {
          for (var i = 0; i < this.elms.length; i++) {
            this._hide(this.elms[i]);
          }
        }

        elm.classList.add(classes.visible);
        var height = body.scrollHeight;
        body.style.height = height + 'px';
      }
    },
    _hide: function _hide(elm) {
      elm.classList.remove(classes.visible);
      elm.style.zIndex = '';
      elm.querySelector('.' + this.options.bodyClass).style.height = 0;
    },
    destroy: function destroy() {
      for (var i = 0; i < this.elms.length; i++) {
        var elm = this.elms[i],
          trigger = elm.querySelector('.' + this.options.triggerClass),
          body = elm.querySelector('.' + this.options.bodyClass);
        elm.classList.remove(classes.active);
        elm.classList.remove(classes.visible);
        if (defaults.elmClass != this.options.elmClass)
          elm.classList.remove(defaults.elmClass);
        if (defaults.triggerClass != this.options.triggerClass)
          trigger.classList.remove(defaults.triggerClass);
        if (defaults.bodyClass != this.options.bodyClass)
          body.classList.remove(defaults.bodyClass);
        trigger.removeEventListener('click', this.listener[i], {
          passive: true,
        });
        elm.style.zIndex = '';
        body.style.transitionDuration = '';
        body.style.height = '';
      }
    },
  };

  return Accordion;
});

class VariousValuesUtil {
  static _sizes = {
    x: window.innerWidth,
    y: window.innerHeight,
    o: window.pageYOffset,
    md: window.matchMedia && window.matchMedia('(max-width: 768px)').matches,
    lg: window.matchMedia && window.matchMedia('(max-width: 1024px)').matches,
    xl: window.matchMedia && window.matchMedia('(max-width: 1280px)').matches,
    t:
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0 ||
      window.matchMedia('(pointer:coarse)').matches ||
      'orientation' in window,
  };
  static get size() {
    return Object.assign({}, this._sizes);
  }
  static get width() {
    return this.size.x;
  }
  static get height() {
    return this.size.y;
  }
  static get offset() {
    return this.size.o;
  }
  static get md() {
    return this.size.md;
  }
  static get lg() {
    return this.size.lg;
  }
  static get xl() {
    return this.size.xl;
  }
  static get touch() {
    return this.size.t;
  }
  static scroll() {
    Object.assign(this._sizes, {
      o: window.pageYOffset,
    });
  }
  static resize() {
    Object.assign(this._sizes, {
      x: window.innerWidth,
      y: window.innerHeight,
      md: window.matchMedia && window.matchMedia('(max-width: 768px)').matches,
      lg: window.matchMedia && window.matchMedia('(max-width: 1024px)').matches,
      xl: window.matchMedia && window.matchMedia('(max-width: 1280px)').matches,
    });
  }
}

export { VariousValuesUtil as default };

// タッチデバイス判定方法
// https://zenn.dev/jamband/scraps/3749203f91875c
// シングルトンパターンのクラス
// https://note.com/aq_kani/n/n7b2589fe48ab

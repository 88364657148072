class ElementOffset {
  constructor(id) {
    // this.header = document.querySelector('.js-header');
    this.id = id;
  }
  get pos() {
    let pos =
      document.getElementById(this.id).getBoundingClientRect().top +
      window.pageYOffset;
    // pos = pos - this.header.offsetHeight;
    return pos;
  }
}

export { ElementOffset as default };

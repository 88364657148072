class Tab {
  constructor(tab = '.js-tab', opt) {
    this.default = {
      height: true,
    };
    this.tab = document.querySelector(tab);
    this.tabTriggers = this.tab.querySelectorAll('.js-tab-trigger');
    this.tabPanel = this.tab.querySelector('.js-tab-panel');
    this.tabPanels = this.tab.querySelectorAll('[data-tab-panel]');
    this.opt = Object.assign(this.default, opt);
    this.init();
  }
  init() {
    if (this.opt.height) {
      for (const trigger of this.tabTriggers) {
        if (trigger.getAttribute('aria-selected') === 'true') {
          const id = trigger.getAttribute('aria-controls');
          this.tabPanel.style.height =
            this.tab.querySelector(`[data-tab-panel="${id}"]`).offsetHeight +
            'px';
        }
      }
    }

    for (const trigger of this.tabTriggers) {
      trigger.addEventListener(
        'click',
        () => {
          const id = trigger.getAttribute('aria-controls');
          const panel = this.tab.querySelector(`[data-tab-panel="${id}"]`);
          if (panel) {
            this.reset();
            if (this.opt.height) {
              this.tabPanel.style.height = panel.offsetHeight + 'px';
            }
            panel.setAttribute('aria-hidden', false);
            trigger.setAttribute('aria-selected', true);
          }
        },
        { paddive: true }
      );
    }
    window.addEventListener('resize', this.resize.bind(this), {
      passive: true,
    });
  }
  reset() {
    for (const trigger of this.tabTriggers) {
      trigger.setAttribute('aria-selected', false);
    }
    for (const panel of this.tabPanels) {
      panel.setAttribute('aria-hidden', true);
    }
  }
  resize() {
    const currentPanel = this.tab.querySelector(
      '[data-tab-panel][aria-hidden="false"]'
    );
    if (this.opt.height) {
      this.tabPanel.style.height = currentPanel.offsetHeight + 'px';
    }
  }
  destroy() {
    window.removeEventListener('resize', this.resize.bind(this), {
      passive: true,
    });
    this.tabPanel.style.height = '';
  }
}

export { Tab };

import ScrollHint from 'scroll-hint';
import VVU from './library/VariousValuesUtil';
import { Tab } from './library/tab';

export default {
  company_about() {
    const scrollable = document.querySelectorAll('.js-scrollable');
    if (scrollable) {
      for (let i = 0; i < scrollable.length; i++) {
        scrollable[i].classList.add(`js-scrollable_${i}`);
        scrollable[i].classList.remove('js-scrollable');
        new ScrollHint(`.js-scrollable_${i}`, {
          i18n: {
            scrollable: '',
          },
        });
      }
    }
  },
  salesprice() {
    if (VVU.lg) {
      this.company_about();
    }

    const trigger = document.querySelector('.js-hidden-trigger');
    const panel = document.querySelector('.js-hidden-panel');
    const toggle = () => {
      trigger.addEventListener(
        'click',
        () => {
          if (panel.classList.contains('is-visible')) {
            trigger.classList.remove('is-visible');
            panel.classList.remove('is-visible');
            panel.style.maxHeight = '';
          } else {
            trigger.classList.add('is-visible');
            panel.classList.add('is-visible');
            panel.style.maxHeight = panel.scrollHeight + 'px';
          }
        },
        { passive: true }
      );
    };

    window.addEventListener(
      'load',
      () => {
        const maxHeight = window
          .getComputedStyle(panel)
          .getPropertyValue('max-height');
        if (panel.scrollHeight > parseInt(maxHeight)) {
          toggle();
        } else {
          trigger.remove();
        }
      },
      { once: true }
    );
  },
  products_lower() {
    const imgs = document.querySelectorAll('.js-scrollable img');
    if (VVU.md && imgs) {
      for (const img of imgs) {
        const image = new Image();
        image.onload = () => {
          const width = image.naturalWidth;
          img.width = width * 0.84;
        };
        image.src = img.getAttribute('src');
      }
    }
    this.company_about();
  },
  contact() {
    const agree = document.querySelector('.js-agree');
    const agreeButton = document.querySelector('.js-agree-button');
    const toggleActive = () => {
      if (agree.checked) {
        agreeButton.classList.remove('is-disable');
      } else {
        agreeButton.classList.add('is-disable');
      }
    };
    if (agree) {
      agree.addEventListener('change', toggleActive, { passive: true });
      window.addEventListener('load', toggleActive, { once: true });
    }

    const select = document.querySelectorAll('.js-select');
    if (select) {
      window.addEventListener(
        'load',
        () => {
          for (const s of select) {
            console.log(s.value);
            if (s.value) {
              s.classList.remove('is-blank');
            } else {
              s.classList.add('is-blank');
            }
          }
        },
        { once: true }
      );

      for (const s of select) {
        s.addEventListener(
          'change',
          () => {
            if (s.value) {
              s.classList.remove('is-blank');
            } else {
              s.classList.add('is-blank');
            }
          },
          { passive: true }
        );
      }
    }
  },
  sustainability_society() {
    this.company_about();
  },
  scrapprice_recycle() {
    this.company_about();
  },
  scrapprice() {
    window.addEventListener(
      'load',
      () => {
        new Tab();
      },
      { once: true }
    );
  },
  scrapprice_application() {
    this.contact();
  },
};

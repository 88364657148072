import { throttle } from 'throttle-debounce';
import VVU from './library/VariousValuesUtil';
import EO from './library/ElementOffset';
import SplitText from './library/SplitText';
import Accordion from './vendor/accordion';

const Common = function () {
  this.body = '';
};

Common.prototype = {
  init() {
    if (location.hash) {
      this.hashScroll();
    }
    window.addEventListener(
      'DOMContentLoaded',
      () => {
        this.body = document.body;
        this.smoothScroll();
        this.megaMenu();
        this.splitText();
        this.hamburgerMenu();
        this.accMenu();
      },
      { once: true }
    );
  },
  splitText() {
    const text = document.querySelectorAll('.js-split-text');
    if (text) {
      for (const t of text) {
        new SplitText(t);
      }
    }
  },
  hashScroll() {
    let hash = location.hash;
    hash = hash.replace('#', '');
    let pos;
    if (hash !== 'top') {
      pos = new EO(hash);
    } else {
      pos = 0;
    }
    setTimeout(() => {
      window.scrollTo(0, pos.pos);
    }, 100);
  },
  smoothScroll() {
    const linkElm = document.querySelectorAll('a');
    const header = document.querySelector('.js-header');
    for (let i = 0; i < linkElm.length; i++) {
      const target = linkElm[i],
        href = target.getAttribute('href');
      if (href && href.startsWith('#')) {
        target.addEventListener(
          'click',
          (e) => {
            e.preventDefault();
            let pos = 0;
            if (href != '#top') {
              const t = document.getElementById(href.replace('#', ''));
              pos = t.getBoundingClientRect().top + window.pageYOffset;
              pos = pos - header.offsetHeight;
            }
            window.scroll({ top: pos, behavior: 'smooth' });
          },
          { passive: false }
        );
      }
    }
  },
  megaMenu() {
    const targets = [];
    const triggers = document.querySelectorAll('[data-megamenu-trigger]');
    const menus = document.querySelectorAll('[data-megamenu]');
    if (menus) {
      for (const menu of menus) {
        const key = menu.getAttribute('data-megamenu');
        targets[key] = menu;
      }
    }
    const toggle = () => {
      const self = event.currentTarget;
      const key = self.getAttribute('data-megamenu-trigger');
      if (event.type === 'mouseover') {
        targets[key].style.height = targets[key].scrollHeight + 'px';
      } else if (event.type === 'mouseout') {
        targets[key].style.height = '';
      }
    };

    if (!VVU.xl) {
      if (triggers) {
        for (const trigger of triggers) {
          trigger.addEventListener('mouseover', toggle);
          trigger.addEventListener('mouseout', toggle);
        }
      }
    }
  },
  hamburgerMenu() {
    const hamburger = document.querySelector('.js-hamburger');
    const root = document.querySelector('.js-root');
    let enable = false;
    let scrollPos = 0;

    const toggle = () => {
      if (!this.body.getAttribute('data-hamburger')) {
        this.body.setAttribute('data-hamburger', 'visible');
        event.currentTarget.classList.add('is-active');
        if (VVU.xl) {
          root.style.position = 'fixed';
          root.style.left = '0px';
          root.style.top = VVU.offset * -1 + 'px';
          scrollPos = VVU.offset;
        }
      } else {
        this.body.removeAttribute('data-hamburger');
        event.currentTarget.classList.remove('is-active');
        if (VVU.xl) {
          root.style.position = '';
          root.style.top = '0px';
          window.scrollTo(0, scrollPos);
          scrollPos = '';
        }
      }
    };
    const resizeFunc = throttle(
      200,
      () => {
        if (!VVU.xl) {
          if (enable) {
            enable = false;
            this.body.removeAttribute('data-hamburger');
            root.style.position = '';
            root.style.top = '';
            root.style.left = '';
            hamburger.removeEventListener('click', toggle, { passive: true });
          }
        } else {
          if (!enable) {
            enable = true;
            hamburger.addEventListener('click', toggle, { passive: true });
          }
        }
      },
      false
    );

    window.addEventListener('resize', resizeFunc, { passive: true });
    if (VVU.xl) {
      enable = true;
      hamburger.addEventListener('click', toggle, { passive: true });
    }
  },
  accMenu() {
    const accOpt = {
      elmClass: 'js-acc-item',
      triggerClass: 'js-acc-trigger',
      bodyClass: 'js-acc-body',
    };
    let acc,
      accF = '';

    const resizeFunc = throttle(
      200,
      () => {
        if (VVU.xl) {
          if (!acc) {
            acc = new Accordion('.js-nav', accOpt);
          }
        } else {
          if (acc) {
            acc.destroy();
            acc = '';
          }
        }
        if (VVU.lg) {
          if (!accF) {
            accF = new Accordion('.js-footer-nav', accOpt);
          }
        } else {
          if (accF) {
            accF.destroy();
            accF = '';
          }
        }
      },
      false
    );

    if (VVU.xl) {
      acc = new Accordion('.js-nav', accOpt);
    }
    if (VVU.lg) {
      accF = new Accordion('.js-footer-nav', accOpt);
    }
    window.addEventListener('resize', resizeFunc, { passive: true });
  },
};

export default new Common();

class SplitText {
  constructor(target) {
    this.target = target;
    this.split();
  }
  split() {
    const text = this.target.textContent;
    const textArray = text.split('');
    this.target.textContent = '';
    this.target.setAttribute('aria-label', text);
    for (let i = 0; i < textArray.length; i++) {
      const wrap = document.createElement('span');
      const span = document.createElement('span');
      span.setAttribute('aria-hidden', 'true');
      if (textArray[i] === ' ') {
        if (this.target.getAttribute('data-br') === 'none') {
          const s = document.createTextNode('\u00a0');
          wrap.appendChild(s);
        } else {
          wrap.classList.add('spacer');
          if (this.target.getAttribute('data-br-md') === 'true') {
            wrap.classList.add('-md');
          }
        }
      } else {
        span.textContent = textArray[i];
        wrap.classList.add('i');
        wrap.appendChild(span);
      }
      this.target.appendChild(wrap);
    }
    // const span = document.createElement('span');
    // span.setAttribute('aria-hidden', 'true');
    // span.textContent = text;
    // span.classList.add('origin');
    // this.target.appendChild(span);
  }
}

export { SplitText as default };

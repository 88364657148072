import smoothscroll from 'smoothscroll-polyfill';
import VVU from './library/VariousValuesUtil';
import intersect from './vendor/intersect';
import common from './common';
import page from './page';

smoothscroll.polyfill();

intersect.init({
  rootMargin: '-20%',
  prefix: 'is',
  //delay: 0,
});
common.init();

document.documentElement.style.setProperty('--vh', VVU.height / 100 + 'px');

window.addEventListener(
  'DOMContentLoaded',
  () => {
    const pageID = document.body.getAttribute('data-page');
    let id = pageID.replaceAll('-', '_');
    if (/^products_.*_/.test(id)) {
      id = 'products_lower';
    }
    if (id in page) page[id]();
  },
  { once: true }
);

window.addEventListener('resize', () => {
  VVU.resize();
  document.documentElement.style.setProperty('--vh', VVU.height / 100 + 'px');
});

window.addEventListener('scroll', () => {
  VVU.scroll();
});

window.addEventListener(
  'load',
  () => {
    document.body.classList.remove('is-preload');
  },
  { once: true }
);
